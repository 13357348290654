import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));

const DonateForm = loadable(() => import("@organisms/DonateForm"));

const DonatePage = ({ data, ...rest }) => {
  const { content, meta } = data;
  return (
    <PageContainer {...rest}>
      <DonateForm content={content} url={meta.url} />
    </PageContainer>
  );
};

DonatePage.defaultProps = {};

export default DonatePage;
