/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/DonatePage";
import resolveImage from "@dataResolvers/resolveImage";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query DonatePageQuery {
    craft {
      entry(section: "donate") {
        ... on Craft_donate_donate_Entry {
          title
          slug
          url
          # Content
          heading0
          descriptor0
          videoUrl
          copy0
          heading2
          descriptor1
          image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          formId: heading1
          # Meta
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    title,
    url,
    slug,
    // Hero
    heading0,
    descriptor0,
    videoUrl,
    copy0,
    heading2,
    descriptor1,
    formId,
    image0,
    // Meta
    metaTitle,
    metaImage,
    metaDescription,
  } = entry;
  return {
    content: {
      heading: heading0,
      descriptor: descriptor0,
      copy: copy0,
      formHeading: heading2,
      formDescriptor: descriptor1,
      formId,
      videoUrl,
      image: resolveImage(image0),
    },
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="DonateQuery"
    {...props}
  />
);

export default Page;
